import React from "react"

import MainNavBar from "../mainNavBar"
import CaptionSiteMap from "./captionSiteMap"

const HeaderSiteMap = ({ siteTitleContact }) => (
  <header className="headerSiteMap">
    <a href="tel:18003582468" className="topRightPhone">1-800-358-2468</a>
    <MainNavBar />
    <CaptionSiteMap />
  </header>
)

export default HeaderSiteMap
