import React from "react"
import { Link } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import LayoutSiteMap from "../components/SiteMap/layoutSiteMap"
import SEO from "../components/seo"

const SiteMapPage = () => (
  <LayoutSiteMap>
    <SEO title="LifeSync Sitemap" />
    <Container className="sitemapWrap">
      <Row>
        <Col className="text-center">
          <ul className="sitemapList">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/cardiac/">Cardiac</Link></li>            
            <li><a href="https://rochestersuperstore.com/" target="_blank" rel="noopener noreferrer">Nuero</a></li>
            <li><Link to="/source/">Source</Link></li>
            <li><Link to="/radiolucent-leadwires/">Radiolucent ECG Leads</Link></li>
            <li><Link to="/standardization/">Standardization</Link></li>
            <li><Link to="/contact/">Contact</Link></li>
            <li><Link to="/cookie-policy/">Cookie Policy</Link></li>            
            <li><Link to="/privacy-policy/">Privacy and Legal</Link></li>
          </ul>
        </Col>
      </Row>
    </Container>
  </LayoutSiteMap>
)

export default SiteMapPage
